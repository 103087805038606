<template>
  <div>
    <div class="container" style="margin-bottom:1rem">
      <el-row>
        <content-title :nav='nav'></content-title>
      </el-row>
      <el-row>
        <div style="float:right;display:flex;justify-content:space-around;align-items:center;width:40%">
          <div @click="changeIndex(1)" :class="{'tabs':true,'active':index==1}">新售</div>
          <div @click="changeIndex(2)" :class="{'tabs':true,'active':index==2}">激活</div>
          <div @click="changeIndex(3)" :class="{'tabs':true,'active':index==3}">续卡</div>
          <div @click="changeIndex(4)" :class="{'tabs':true,'active':index==4}">本月到期</div>
        </div>
      </el-row>

    </div>
    <div class="container">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="用户手机号">
          <el-input v-model="options.mobile"></el-input>
        </el-form-item>
        <el-form-item label="渠道来源">
          <el-select v-model="options.platform">
            <!-- <el-option label='合作商' ></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="所属销售">
          <el-select v-model="options.admin">
            <!-- <el-option label="王小明" ></el-option> -->
          </el-select>
        </el-form-item>
      </el-form>
      <el-row>
        <el-table :data="user_card_list" v-loading='is_loading'>
          <el-table-column label="ID" prop="id"></el-table-column>
          <el-table-column label="卡号" prop="card_id"></el-table-column>
          <el-table-column label="发卡时间" prop="start_time"></el-table-column>
          <el-table-column label="用户昵称" prop="map.userinfo.nick_name"></el-table-column>
          <el-table-column label="用户手机号" prop="map.userinfo.mobile"></el-table-column>
          <el-table-column label="渠道来源" prop=""></el-table-column>
          <el-table-column label="所属销售" prop=""></el-table-column>
          <el-table-column label="到期时间" prop="expired_time"></el-table-column>
        </el-table>

          <!-- 分页 -->
          <paging-fy
          @currentPageChange="changeCurrentPage"
          :currentPage="currentPage"
          :total="card_total"
        ></paging-fy>
       
      </el-row>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      nav: {
        firstNav: '会员卡管理',
        secondNav: '专享卡管理',
      },
      user_card_list: [],
      card_total: 0,
      is_loading: false,
      currentPage: 1,
      index:1,
      options:{},
      // member_level:
    }
  },
  mounted() {
    // this.getZhuanxiangId().then(res=>{
    //   if(res.data.data.rows.length){
    //     this.member_level = res.data.data.rows[0].member_level
    this.getUserCardList()
    // }
    // })
  },
  methods: {
    getZhuanxiangId() {
      let url = '/user/userMemberLevel/queryManagerListPage'
      return this.$axios.get(url, {
        params: {
          pageSize: 10,
          status: 1,
          currentPage: 1,
          platform: '体育宝',
          member_name: '优学卡',
        },
      })
    },
    changeIndex(index){
      this.currentPage = 1
      this.options = {}
      this.index = index
      switch (index){
        case 1:
          
      }
    },
    getUserCardList(num) {
      this.is_loading = true
      let url = '/user/userMember/queryManagerListPage'

      this.$axios
        .get(url, {
          params: {
            pageSize: 10,
            currentPage: this.currentPage,
            type: '亲子卡',
            mobile:this.options.mobile
            // ...this.options
            // member_level: this.member_level,
          },
        })
        .then((res) => {
          this.card_total = res.data.data.total
          this.user_card_list = res.data.data.rows
          this.is_loading = false
        })
    },
    changeCurrentPage(v) {
      this.currentPage = v
      this.getUserCardList()
    },
  },
}
</script>

<style>
.tabs{
  cursor: pointer;
  padding: 0.3rem;
  font-size: 1.5rem;
}
.tabs.active{
  color: #6D9FFF;
  border-bottom: 1px solid #6D9FFF;
}
</style>